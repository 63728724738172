.rmwc-icon {
}

/**
 * Adds disabled styles for the icon directly,
 * and an icon that is a direct descendent of a disabled element like IconButtons
 */
.rmwc-icon--url[disabled],
.rmwc-icon--component[disabled],
[disabled] > .rmwc-icon--url,
[disabled] > .rmwc-icon--component {
  opacity: 0.38;
}

.rmwc-icon--url {
  min-width: 1em;
  min-height: 1em;
  background-repeat: no-repeat;
  font-size: 1.5rem;
  background-size: 1em;
  background-position: center center;
}

.rmwc-icon--size-xsmall {
  font-size: 1.125rem;
  width: 1em;
  height: 1em;
}

.rmwc-icon--size-small {
  font-size: 1.25rem;
  width: 1em;
  height: 1em;
}

.rmwc-icon--size-medium {
  font-size: 1.5rem;
  width: 1em;
  height: 1em;
}

.rmwc-icon--size-large {
  font-size: 2.25rem;
  width: 1em;
  height: 1em;
}

.rmwc-icon--size-xlarge {
  font-size: 3rem;
  width: 1em;
  height: 1em;
}
