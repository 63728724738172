//
// Copyright 2019 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use "sass:list";
@use "@material/animation/functions";
@use "@material/elevation/mixins";
@use "@material/feature-targeting/functions" as feature-targeting-functions;
@use "@material/feature-targeting/mixins" as feature-targeting-mixins;
@use "@material/checkbox/mixins" as checkbox-mixins;
@use "@material/density/functions" as density-functions;
@use "@material/rtl/mixins" as rtl-mixins;
@use "@material/shape/mixins" as shape-mixins;
@use "@material/theme/mixins" as theme-mixins;
@use "@material/theme/variables" as variables2; // for mdc-theme-prop-value.
@use "@material/typography/mixins" as typography-mixins;
@use "./variables";

@mixin core-styles($query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);
  $feat-typography: feature-targeting-functions.create-target($query, typography);

  // postcss-bem-linter: define data-table

  .mdc-data-table__content {
    @include typography-mixins.typography(body2, $query: $query);
  }

  .mdc-data-table {
    @include fill-color(variables.$fill-color, $query: $query);
    @include shape-radius(variables.$shape-radius, $query: $query);
    @include stroke-size(variables.$stroke-size, $query: $query);
    @include stroke-color(variables.$stroke-color, $query: $query);

    @at-root {
      @include row-fill-color(variables.$row-fill-color, $query: $query);
      @include header-row-fill-color(variables.$header-row-fill-color, $query: $query);
      @include selected-row-fill-color(variables.$selected-row-fill-color, $query: $query);
      @include divider-color(variables.$divider-color, $query: $query);
      @include divider-size(variables.$divider-size, $query: $query);
      @include row-hover-fill-color(variables.$row-hover-fill-color, $query: $query);
      @include header-row-text-color(variables.$header-row-text-color, $query: $query);
      @include row-text-color(variables.$row-text-color, $query: $query);
      @include density(variables.$default-density-scale, $query: $query);
      @include cell-padding(
        $leading-padding: variables.$cell-leading-padding,
        $trailing-padding: variables.$cell-trailing-padding,
        $query: $query
      );
    }

    @include feature-targeting-mixins.targets($feat-structure) {
      -webkit-overflow-scrolling: touch; // Lets it scroll lazy (iOS)
      display: inline-flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow-x: auto;
    }
  }

  .mdc-data-table__table {
    @include feature-targeting-mixins.targets($feat-structure) {
      min-width: 100%; // Makes table full-width of its container (Firefox / IE11)
      border: 0;
      white-space: nowrap;
      border-collapse: collapse;
      /**
       * With table-layout:fixed, table and column widths are defined by the width
       * of the first row of cells. Cells in subsequent rows do not affect column
       * widths. This results in a predictable table layout and may also speed up
       * rendering.
       */
      table-layout: fixed;
    }
  }

  .mdc-data-table__cell {
    @include typography-mixins.typography(body2, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      box-sizing: border-box;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .mdc-data-table__cell--numeric {
    @include feature-targeting-mixins.targets($feat-structure) {
      text-align: right;
    }

    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.rtl {
        /* @noflip */
        text-align: left;
      }
    }
  }

  .mdc-data-table__header-cell {
    @include typography-mixins.typography(subtitle2, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      box-sizing: border-box;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;

      @include rtl-mixins.rtl {
        /* @noflip */
        text-align: right;
      }
    }
  }

  .mdc-data-table__header-cell--numeric {
    @include feature-targeting-mixins.targets($feat-structure) {
      text-align: right;
    }

    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.rtl {
        /* @noflip */
        text-align: left;
      }
    }
  }
}

@mixin fill-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  @include feature-targeting-mixins.targets($feat-color) {
    @include theme-mixins.prop("background-color", $color);
  }
}

@mixin header-row-fill-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__header-row {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop("background-color", $color);
    }
  }
}

@mixin row-fill-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__row {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop("background-color", $color);
    }
  }
}

@mixin selected-row-fill-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__row--selected {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop("background-color", $color);
    }
  }
}

@mixin checked-icon-color($color, $query: feature-targeting-functions.all()) {
  .mdc-data-table__header-row-checkbox,
  .mdc-data-table__row-checkbox {
    @include checkbox-mixins.focus-indicator-color($color, $query: $query);
    @include checkbox-mixins.container-colors($marked-stroke-color: $color, $marked-fill-color: $color, $query: $query);
  }
}

@mixin divider-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__row {
    @include feature-targeting-mixins.targets($feat-color) {
      border-top-color: $color;
    }
  }
}

@mixin divider-size($size, $query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);

  .mdc-data-table__row {
    @include feature-targeting-mixins.targets($feat-structure) {
      border-top-width: $size;
      border-top-style: solid;
    }
  }
}

@mixin row-hover-fill-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop("background-color", $color);
    }
  }
}

@mixin header-row-text-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__header-cell {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop("color", $color);
    }
  }
}

@mixin row-text-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  .mdc-data-table__cell {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop("color", $color);
    }
  }
}

@mixin shape-radius($radius, $rtl-reflexive: false, $query: feature-targeting-functions.all()) {
  @include shape-mixins.radius($radius, $rtl-reflexive, $query: $query);
}

@mixin stroke-size($size, $query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);

  @include feature-targeting-mixins.targets($feat-structure) {
    border-width: $size;
    border-style: solid;
  }
}

@mixin stroke-color($color, $query: feature-targeting-functions.all()) {
  $feat-color: feature-targeting-functions.create-target($query, color);

  @include feature-targeting-mixins.targets($feat-color) {
    border-color: $color;
  }
}

@mixin header-cell-height($height, $query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);

  .mdc-data-table__header-cell {
    @include feature-targeting-mixins.targets($feat-structure) {
      height: $height;
    }
  }
}

@mixin cell-height($height, $query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);

  .mdc-data-table__cell {
    @include feature-targeting-mixins.targets($feat-structure) {
      height: $height;
    }
  }
}

@mixin cell-padding(
  $leading-padding: variables.$cell-leading-padding,
  $trailing-padding: variables.$cell-trailing-padding,
  $query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    @include feature-targeting-mixins.targets($feat-structure) {
      padding-right: $trailing-padding;
      padding-left: $leading-padding;
    }
  }

  .mdc-data-table__header-cell--checkbox,
  .mdc-data-table__cell--checkbox {
    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.reflexive-property(padding, $leading-padding, 0);
    }
  }
}

@mixin column-widths($width-list, $query: feature-targeting-functions.all()) {
  $feat-structure: feature-targeting-functions.create-target($query, structure);

  @for $i from 1 through list.length($width-list) {
    .mdc-data-table__row > :nth-child(#{$i}) {
      @include feature-targeting-mixins.targets($feat-structure) {
        width: list.nth($width-list, $i);
      }
    }
  }
}

///
/// Sets density scale for data table. Use corresponding density mixins of child components (such as Checkbox) to apply
/// density scales which will be rendered inside data table.
///
/// @param {Number | String} $density-scale - Density scale value for component. Supported density scale values `-4`,
///     `-3`, `-2`, `-1`, `0`.
///
@mixin density($density-scale, $query: feature-targeting-functions.all()) {

  $height: density-functions.prop-value(
    $density-config: variables.$density-config,
    $density-scale: $density-scale,
    $property-name: height,
  );

  @include cell-height($height, $query: $query);
  @include header-cell-height(variables.get-header-cell-height($height), $query: $query);
}

@mixin theme-baseline($query: feature-targeting-functions.all()) {
  @include checked-icon-color(variables.$checked-icon-color, $query: $query);
}
