.rmwc-collapsible-list {
  width: 100%;
}

.rmwc-collapsible-list__children {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s, opacity 0.3s;
  opacity: 0;
}

.rmwc-collapsible-list--open > .rmwc-collapsible-list__children {
  opacity: 1;
}

.rmwc-collapsible-list__handle .mdc-list-item__meta {
  transition: transform 0.3s;
  user-select: none;
}

.rmwc-collapsible-list--open
  > .rmwc-collapsible-list__handle
  .mdc-list-item__meta {
  transform: rotate(90deg);
}

.rmwc-collapsible-list__children .mdc-list-item {
  padding-left: 1.5rem;
}

/** No good way to do this dynamically, adding rules to support multiple depths */
/** 3 levels */
.rmwc-collapsible-list
  .rmwc-collapsible-list
  .rmwc-collapsible-list__children
  .mdc-list-item {
  padding-left: 2.5rem;
}

/** 4 levels */
.rmwc-collapsible-list
  .rmwc-collapsible-list
  .rmwc-collapsible-list
  .rmwc-collapsible-list__children
  .mdc-list-item {
  padding-left: 3.5rem;
}

/** 5 levels */
.rmwc-collapsible-list
  .rmwc-collapsible-list
  .rmwc-collapsible-list
  .rmwc-collapsible-list
  .rmwc-collapsible-list__children
  .mdc-list-item {
  padding-left: 4.5rem;
}

.rmwc-collapsible-list__children-inner {
  overflow: auto;
}
