/** 
 * DataTable
 */

/** DataTable Sticky Rules */
.rmwc-data-table--sticky-columns-1 .rmwc-data-table__cell:nth-child(-n + 1) {
  position: sticky;
  left: 0;
  z-index: 1;
  box-shadow: 1px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, white);
}

.rmwc-data-table--sticky-rows-1
  .rmwc-data-table__head
  .rmwc-data-table__row:nth-child(-n + 1)
  .rmwc-data-table__cell {
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 1px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, white);
}

/** 1 x 1 */
.rmwc-data-table--sticky-rows-1.rmwc-data-table--sticky-columns-1
  .rmwc-data-table__head
  .rmwc-data-table__row:nth-child(-n + 1)
  .rmwc-data-table__cell:nth-child(-n + 1) {
  z-index: 3;
}

.rmwc-data-table--sticky-rows-1.rmwc-data-table--sticky-columns-1
  .rmwc-data-table__head
  .rmwc-data-table__row:nth-child(-n + 1)
  .rmwc-data-table__cell:nth-child(-n + 1) {
  box-shadow: 1px 1px rgba(0, 0, 0, 0.12);
}

/**
 * DataTableRow
 */
.rmwc-data-table__cell {
  position: relative;
}

.rmwc-data-table__row--selected .rmwc-data-table__cell:before {
  opacity: 0.1;
  background-color: var(--mdc-theme-on-surface, black);
}

.mdc-data-table__row--selected {
  background-color: initial;
}

.mdc-data-table__row--selected .rmwc-data-table__cell:before {
  opacity: 0.05;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

/** Correct selected style to make it themeable */
.mdc-data-table__row--selected .rmwc-data-table__cell:before {
  background-color: var(--mdc-theme-primary, #6200ee);
}

/** Correct selected style to make it themeable */
.rmwc-data-table__row--activated {
  background-color: var(--mdc-theme-primary, #6200ee);
}

.rmwc-data-table__row--activated .rmwc-data-table__cell {
  color: var(--mdc-theme-on-primary, white);
}

.rmwc-data-table__cell--align-end {
  text-align: right;
}

.rmwc-data-table__cell--align-start {
  text-align: left;
}

.rmwc-data-table__cell--align-middle {
  text-align: center;
}

/**
 * DataTableSortIcon
 */
.rmwc-data-table__sort-icon {
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  vertical-align: middle;
  width: 1.125rem;
  height: 1.125rem;
  display: inline-flex;
  margin-right: 0.25rem;
  margin-top: -0.1875rem;
}

.rmwc-data-table__head-cell--sortable {
}

.rmwc-data-table__head-cell--sorted .rmwc-data-table__sort-icon {
  opacity: 1;
}

.rmwc-data-table__head-cell--sorted-descending .rmwc-data-table__sort-icon {
}

.rmwc-data-table__head-cell--sorted-ascending .rmwc-data-table__sort-icon {
  /** Do stuff with sorted icons */
  transform: scaleY(-1);
}

/** Sortable Rules */
.rmwc-data-table__head-cell--sortable {
  transition: color 0.3s;
}

.rmwc-data-table__head-cell--sortable-hover,
.rmwc-data-table__head-cell--sortable:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
}

.rmwc-data-table__head-cell--sorted {
  color: rgba(0, 0, 0, 0.87);
}

/**
 * Form Controls
 */
/** Checkbox / Radio */
.rmwc-data-table__cell .mdc-radio {
  vertical-align: middle;
  --mdc-theme-secondary: var(--mdc-theme-primary, #6200ee);
}

/** Select */
.rmwc-data-table__cell .mdc-select {
  vertical-align: middle;
  margin: -0.75rem -1rem;
  background-color: transparent;
  border-radius: 0;
}

.rmwc-data-table__cell
  .mdc-select:not(.mdc-select--disabled)
  .mdc-select__anchor {
  background-color: transparent;
}

.rmwc-data-table__cell .mdc-select .mdc-select__native-control {
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: inherit;
  font-size: inherit;
}

.rmwc-data-table__cell .mdc-select option {
  font-size: 1rem;
}

.rmwc-data-table__cell .mdc-select .mdc-line-ripple {
  display: none;
}

/** Switch */
.rmwc-data-table__cell .mdc-switch {
  vertical-align: middle;
  margin-left: 0.5rem;
  --mdc-theme-secondary: var(--mdc-theme-primary, #6200ee);
}
