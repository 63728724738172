.mdc-select__anchor {
  width: 100%;
}

.rmwc-select__native-control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}
